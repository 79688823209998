export const showLoader = () => dispatch => {
    window.scrollTo(0, 0);
    dispatch({
      type: "SHOW_LOADER"
    })
  }
  
  export const hideLoader = () => dispatch => {
    window.scrollTo(0, 0);
    dispatch({
      type: "HIDE_LOADER"
    })
  }

  export const setUrl = (data) => dispatch => {
    dispatch({
      type: 'WATCH_URL',
      videoInfo: data
    })
  }

  export const PreviousUrl = (url) => dispatch => {
    dispatch({
      type: 'PREVIOUS_URL',
      url
    })
  }

  export const validateUser = (memberValid) =>dispatch => {
    dispatch({
      type: 'VALID_USER',
      memberValid
    })
  } 
