import React from 'react'
import './login.scss'
import logo from '../../assets/images/logo.png';
import 'react-phone-input-2/lib/style.css'
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'; 

import { useEffect } from 'react'



function Login(props) {
    useEffect(()=>{
        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
        ui.start('.firebase-auth-container', {
            signInOptions:[
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                firebase.auth.EmailAuthProvider.PROVIDER_ID,
                // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                // {
                //     provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                //     defaultCountry: 'IN'
                // }
                
            ],
            signInFlow: 'popup',
            privacyPolicyUrl: 'https://www.shortflixindia.com/privacy',
            signInSuccessUrl: '/home',
            redirectUrl: '/home'
        },[props.auth])
    });
    
  return (
    <div className='login-main flex justify-content-center'>
        <div className='login-main-inner'>
            <img src={logo} className="App-logo" alt="logo" />
            <h3>Sign in to your account</h3>
            <div className={'firebase-auth-container'}></div>
        </div>
    </div>
  )
}

export default Login