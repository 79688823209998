import React from 'react'
import './about.scss'
import logo from '../../assets/images/logo.png';
function About() {
  window.scrollTo(0, 0);
  return (
    <>
    <header className='header flex align-items-center justify-content-between'>
        <div className='heder-left flex align-items-center'>
            <a href="#1" className='flex'><img src={logo} className="App-logo" alt="logo" /></a>
        </div>
    </header>
    <div className='main-body about-body'>
        <h1>ABOUT US</h1>
        <p>Welcome to ShortFlix!</p>
        <p>Exclusive OTT platform for OTT Movies / Web Series / Short films. ShortFlix is your one-stop destination for original and award-winning exclusive short-format content.</p>
        <p>We provide an incredible platform for upcoming and next-gen creative filmmakers and entertainers to showcase their talents and become icons in the film industry.</p>
        <p>Watch any Short Film, Series, or OTT Movie anywhere and anytime on the go. We make your watching experience even easier with our short descriptions and categorization based on genre.</p>
        <p>Plus, with more and more amazing content added all the time, you'll never run out of films to watch. </p>
        <p>ShortFlix is available on Android play store, iOS App store, Android smart TV, Amazon Firetv, Jio set-top box, Airtel Xtreme, and Tata sky set top boxes. Download now and start streaming ! </p>

        <h3>UPLOAD PROCESS</h3>
        <p>Sharing your content is a very easy process. Reach us through email  <a href="mailto:info@shortflixindia.com" target="_blank" rel="noreferrer">info@shortflixindia.com</a> we will assist you.</p>
        <p>Our creative team will review your content and if it is approved by our creative team then we will stream your content in our ShortFlix OTT app for the viewers across the world.</p>
        <h3>CONTACT</h3>
        <ul>
            <li>Website: <a href="https://www.shortflixindia.com/">https://www.shortflixindia.com/</a></li>
            <li>For Complaints: <a href="mailto:care@shortflixindia.com" target="_blank" rel="noreferrer">care@shortflixindia.com</a></li>
            <li>For Enquiries: <a href="mailto:info@shortflixindia.com" target="_blank" rel="noreferrer">info@shortflixindia.com</a></li>
        </ul>
        
    </div>
    </>
  )
}

export default About