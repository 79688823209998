import React, {Component} from 'react'
import './accountSettings.scss'
import profile from '../../assets/images/profile.svg'
import CryptoJS from 'crypto-js';
import ApiServices from '../../services/apiServices'
import firebase from 'firebase/compat/app';
import { Link } from "react-router-dom";
class AccountSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userInfo: {},
      userPlan: {},
      memberShip: false
    }
  }
  componentWillMount() {
    if(localStorage.getItem('userKey')) {
      const bytes = CryptoJS.AES.decrypt(localStorage.getItem('userKey'), process.env.REACT_APP_SECERET_KEY);
      const userInfo = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      console.log('userInfo',userInfo);
      this.setState({userInfo: userInfo})
      this.checkMembership();
    }
  }
  async checkMembership() {
    ApiServices.callGetAPI('membership/validate').then(result=>{
      if(result.status === 200 && result.data.valid) {
        const active = result.data.data.premiumuser
        this.setState({memberShip: active,
          userPlan: result.data.data
          });
        } else {
          this.setState({memberShip: false});
        }
    }).catch(error=>{ 
      console.log(error)
      this.setState({memberShip: false});
    })
  }
  render() {
    return (
      <div className='acc-body flex justify-content-center'>
          <div className='acc-body-inner'>
              <h1>Account & Settings</h1>
              {
                this.state.userInfo.photoURL ? 
                <img src={this.state.userInfo.photoURL} className="App-profile" alt="profile pic" 
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src={profile};
              }} />
              : 
              <img src={profile} className="App-profile" alt="profile pic" />
              }
              <h2>{this.state.userInfo.displayName ? this.state.userInfo.displayName : ''}</h2>
              <p>{this.state.userInfo.email ? this.state.userInfo.email : ''}</p>
              <p>{this.state.userInfo.phoneNumber ? this.state.userInfo.phoneNumber : ''}</p>
              {this.state.memberShip && this.state.userPlan ? 
              <div className='plan-section'>
                {/* <h3>My Subscription</h3> */}
                <p className='heading'>Thanks for choosing ShortFlix. You have purchased our  <span className='plan-details'>{this.state.userPlan.membershiptype}</span></p>
                <p>Started On: <span className='plan-details'>{this.state.userPlan.starton}</span></p>
                <p>Ends On: <span className='plan-details'>{this.state.userPlan.endon}</span></p>
                <p className='heading' >You have to renew your subscription in<span className='plan-details'>{this.state.userPlan.opendays}</span> days</p>
              </div>: 
              

                <button className='button'>
                  <Link to={'/subscricption'}>Subscribe</Link>
                </button>
              
              }
              
              <button className='button none' onClick={()=> {firebase.auth().signOut()}} >Logout</button>
          </div>
      </div>
    )
  }
}


export default AccountSettings