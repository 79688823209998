import React, { Component } from 'react';

import './home.scss'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper";
import Card from '../../components/card/Card';
import ApiServices from '../../services/apiServices';
import { connect } from "react-redux";
import CryptoJS from 'crypto-js';
import withRouter from '../../services/withRouter';
import { showLoader, hideLoader } from "../../action/application";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerData: [],
      latestMovies: [],
      loader: false,
      moviesList: [],
    };
    // this.infiniteScroll = this.infiniteScroll.bind(this);   
    
  }
  componentWillMount() {
    this.props.dispatch( showLoader() )
    console.log('windows', window.location.pathname);
    if(localStorage.getItem('path') && localStorage.getItem('path').startsWith('/tv') && window.location.pathname !== '/') {
      const path = localStorage.getItem('path')
      localStorage.removeItem('path');
      window.location.href = path;
    } else {
      localStorage.removeItem('path');
      this.getHomeBanner();
      this.getLatestMovies();
    }
    
  }
  // infiniteScroll = () => {
  //   console.log('scroll');
  //   const elem = this.isInViewport(document.querySelector('#reach-end'))
  //   console.log(elem)
  //   if(elem) {
  //     this.setState({loader: true},()=>{
        
  //     })
  //   }
  //   if (window.innerHeight + document.documentElement.scrollTop
  //     >= (document.documentElement.offsetHeight- 50)){
          
  //     }
  // }

  isInViewport(el) {
    if (el) {
      const rect = el.getBoundingClientRect();
      return (
          rect.top <= (window.innerHeight || document.documentElement.clientHeight) && rect.left <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }
    return false;
  }
  componentDidMount(){
    ApiServices.MailGetAPI().then(result=>{
      console.log('otherapi result', result)
    }).catch(error=>{
      console.log(error)
    })
  }
  getMovieList() {
    ApiServices.callGetAPI('movies/list?limit=2').then(result=>{
      if(result.status === 200 && result.data.valid) {
        const resData = this.state.latestMovies? [ ...this.state.latestMovies, ...result.data.data  ]: result.data.data;
        this.setState({latestMovies: resData})
      }
    }).catch(error=>{
      console.log(error)
    })
  }
  async getHomeBanner(){
    ApiServices.callGetAPI('movies/banner').then(result=>{
      if(result.status === 200 && result.data.valid) {
        this.setState({bannerData: result.data.data});
      }
    }).catch(error=>{
      console.log(error)
    })
  }
  async getLatestMovies() {
    
    ApiServices.callGetAPI('movies/latest').then(result=>{
      if(result.status === 200 && result.data.valid) {
        this.setState({latestMovies: result.data.data});
        if(localStorage.getItem('userKey')) {
          const bytes = CryptoJS.AES.decrypt(localStorage.getItem('userKey'), process.env.REACT_APP_SECERET_KEY);
          const userInfo = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          this.getRecomendation(userInfo.uid);
        }else {
          this.getMovieList(); 
        }
        
      }
      this.props.dispatch( hideLoader() )
    }).catch(error=>{
      console.log(error)
    })
  }
  getRecomendation(userId) {
    ApiServices.callPostAPI('movies/recommendation',{
      channel: "Web",
      userid: userId
    }).then(result=>{
      if(result.status === 200 && result.data.valid) {
        const resData = this.state.latestMovies? [ ...this.state.latestMovies, ...result.data.data  ]: result.data.data;
        this.setState({latestMovies: resData})
      }
      this.getMovieList();
    }).catch(error=> {
      console.log(error)
    })
  }
  render() {
    return (
      <div className='page container'>
        <div className='banner'>
          <Swiper 
            pagination={{clickable: true,}} 
            modules={[Pagination, Autoplay]} 
            speed={1200}
            loop={true} 
            autoplay={{delay: 2500,disableOnInteraction: false,}} className="mySwiper">
              {
                this.state.bannerData.map((banner, i) => (
                  <SwiperSlide><img key={i} src={banner.bannerImage[0]} alt="" /></SwiperSlide>
                ))
              }
          </Swiper>
        </div>
        <div className='main-body'>
          {
          this.state.latestMovies.map((movieList,i) =>(
            <Card key={i} cardType={movieList.displayOrder === 3 ? 'continue' : 'video'} cardData={movieList}/>
          ))
          }
          {/* <Card cardType={'skeleton'}/> */}
          {/* <div id="reach-end"></div> */}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({ loading: state.application.loading });


export default withRouter(connect(mapStateToProps)(Home))