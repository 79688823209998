import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { BrowserRouter } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom'
import App from './App';
import TermsConditions from './pages/TermsConditions/TermsConditions';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import About from './pages/About/About';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import configureStore from "./store"
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={configureStore()}>
    <BrowserRouter>
    <Routes>
        <Route path="terms" element={<TermsConditions />} />
        <Route path="privacy" element={<PrivacyPolicy />} />
        <Route path="about" element={<About />} />
        {/* <Route path="/" render={props => {...props} />} /> */}
    </Routes>
    <App />
    </BrowserRouter>
    </Provider>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
