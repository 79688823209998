import React, {Component} from 'react';
import { RingLoader } from 'react-spinners';
import {connect} from 'react-redux';


class AppLoader extends Component {
    state = {  }
    render() { 
        const {loading} = this.props;

        if(!loading) return null;

        return ( 
            <div class="loader-container flex align-items-center justify-content-center">
                <div className="loader">
                    <RingLoader loading="true" size={150} aria-label="Loading Spinner" data-testid="loader" color="#db0ce6" />
                </div>
            </div>
         );
    }
}

const mapStateToProps = state => ({ loading: state.application.loading })

export default connect(mapStateToProps)(AppLoader);