import React, { Component, Fragment} from 'react'
import './price.scss';
import ApiServices from '../../services/apiServices';
import withRouter from '../../services/withRouter';
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../action/application";
// import useRazorpay from "react-razorpay";
import CryptoJS from 'crypto-js';
class Price extends Component {
    userInfo = {}
    constructor(props) {
        super(props);
        this.state = {
            planList : [],
            loadSkelton: false,
            activeCurrency: 'INR',
            selectedIndex: 0
          };
    }
    componentWillMount() {
        this.props.dispatch( showLoader() )
        this.getPlans()
        if(localStorage.getItem('userKey')) {
            const bytes = CryptoJS.AES.decrypt(localStorage.getItem('userKey'), process.env.REACT_APP_SECERET_KEY);
            this.userInfo = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          }
    }
    getPlans() {
        ApiServices.callGetAPI('membership/available').then(result=>{
          if(result.data.valid) {
            result.data.data.map(plans => {
                plans.selectedIndex = 0;
                if(plans.membershipchoices.length > 0) {
                  plans.selected_id = plans.membershipchoices[0].membership_id
                }
                
            })
            this.setState({planList: result.data.data});
            this.props.dispatch( hideLoader() );           
            }
            
        }).catch(error=>{ 
          console.log(error)
        })
      }
    subscribe(selectPlans) {
        console.log(selectPlans)
        const orderInfo = {
            membershipid: selectPlans.selected_id,
            currency: this.state.activeCurrency,
            name: this.userInfo.displayname ? this.userInfo.displayname : '',
            email: this.userInfo.email ? this.userInfo.email : '',
            contact: this.userInfo.phoneNumber ? this.userInfo.phoneNumber : ''
        }
        this.createOrder(orderInfo);
    }
    createOrder(orderData){
        ApiServices.callPostAPI('membership/order',orderData).then(result=>{
            console.log(result);
          if(result.status && result.data.valid) {
            if(result.data.data.amount > 0){
              this.initPay({
                id: result.data.data.id,
                amount: result.data.data.amount,
                order_id: result.data.data.refId,
                currency: result.data.data.currency,
              });
            } else {
              const orderData = {
                status:  'Success',
                paymentId: result.data.data.id,
                orderId: result.data.data.refId,
                signature: '3bb9dcbb089f0d4f5a790c0877a97009bd9b2d4e405318ec60b93d39c7c3c371',
                errorcode:  0 ,
                errortext: 'Success'
              }
              this.updateOrder(orderData);
            }
            
          }
            
        }).catch(error=>{ 
          this.props.navigate(this.props.url);
          console.log(error)
        })
    }
    updateOrder(paymentInfo) {
        ApiServices.callPutAPI('membership/order',paymentInfo).then(result=>{
          if(result.status && result.data.valid) {
            window.history.back();
            this.props.navigate(this.props.PreviousUrl);
          }
            
        }).catch(error=>{ 
          console.log(error)
        })
    }
    initPay(order) {
        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY,
          amount: order.amount,
          order_id: order.order_id,
          currency: order.currency,
          name: process.env.REACT_APP_APP_NAME,
          description: `Enjoy watching Movies on Shortflix`,
          image: `/assets/logo.png`,
          handler: (response) => {
            const orderData = {
              status: response ? 'Success' : 'Failed',
              paymentId: response.razorpay_payment_id,
              orderId: response.razorpay_order_id,
              signature: response.razorpay_signature,
              errorcode: response ? 0 : 1,
              errortext: response ? 'Success' : 'Failed'
            }
            console.log('response',response);
            this.updateOrder(orderData);
          },
          prefill: this.profile
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
      }
    selectChoice(Index, choice, id) {
        const planData =  this.state.planList;
        planData[Index].selectedIndex=choice;
        planData[Index].selected_id=id;
        this.setState({planList: planData})
    }
    swichCurrency($event) {
      let activeCurrency = ''
      if($event.target.checked) {
        activeCurrency = 'USD';
        
      } else {
        activeCurrency = 'INR';
      }
      this.setState({
        activeCurrency: activeCurrency
      })
    }
    render() {        
        return (
            <div className='main-body price-body'>
              <div className='toggle '>
                <span className='roboto'>₹ INR</span>
                <input type="checkbox" id="switch" onChange={(e) => this.swichCurrency(e)}/><label for="switch">Toggle</label>
                <span className='roboto'>$ USD</span>
              </div>
                {
                    this.state.planList.length > 0 ? 
                    <ul className="pricingTable-firstTable">
                    {
                        this.state.planList.map((plan, i)=> (
                            <li className="pricingTable-firstTable_table">
                            <h1 className="pricingTable-firstTable_table__header">{plan.membershiptype}</h1>
                            <ul className="pricingTable-firstTable_table__options">
                                
                               { plan.membershiptext.split('-').map((text, i) =>(
                                  <>
                                 {text.length >0 ?<li>{text}</li> : ''} 
                                  </>
                               ))

                                
                              }
                            </ul>
                            {this.state.activeCurrency === 'INR' ? 
                                <div className='price flex justify-content-center'>
                                    {
                                        plan.membershipchoices.map((price, j)=> (
                                          <Fragment>
                                            { price.inr_actual_amount ? 
                                              <p className={`pricingTable-firstTable_table__pricing ${plan.selectedIndex === j? "active" : ""}`} onClick={(e) => this.selectChoice(i,j, price.membership_id)}>
                                              {
                                                  price.inr_show_offer ? 
                                                  <><del className='roboto'>&#x20b9;{price.inr_actual_amount}</del><span className='roboto'>₹</span><span>{price.inr_offer_amount}</span></>
                                                  :<><span>&#x20b9;</span><span>{price.inr_actual_amount}</span></>
                                              } 
                                              <span>{price.membership_display_name}</span></p>
                                              :''
                                            }
                                            
                                            </Fragment>
                                        ))
                                    }
                                </div>:
                                    <div className='price flex justify-content-center'>
                                    {
                                        plan.membershipchoices.map((price, k)=> (
                                          <Fragment>
                                            { price.usd_actual_amount ?
                                              <p className ={`pricingTable-firstTable_table__pricing ${plan.selectedIndex === k? "active" : ""}`} onClick={(e) => this.selectChoice(i,k, price.membership_id)}>
                                                {
                                                    price.usd_show_offer ? 
                                                    <><del>${price.usd_actual_amount}</del><span>$</span><span>{price.usd_offer_amount}</span></>
                                                    :<><span>$</span><span>{price.usd_actual_amount}</span></>
                                                }
                                                <span>{price.membership_display_name}</span></p>
                                                : ''
                                            } 
                                            </Fragment>
                                        ))
                                    }
                                </div>
                                }
                            <button className="pricingTable-firstTable_table__getstart" onClick={(e) => this.subscribe(plan)}>Subscribe Now</button>
                        </li>
                        ))
                    }
                    
                  </ul>
                : <ul className="pricingTable-firstTable">
                        <li className="pricingTable-firstTable_table">
                            No Plan Aviable
                        </li>
                </ul>
                }
                
            </div>
          )
    }
}
const mapStateToProps = state => ({ loading: state.application.loading, PreviousUrl: state.application.PreviousUrl});
export default withRouter(connect(mapStateToProps)(Price))