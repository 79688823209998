import React, { Component} from 'react'
import withRouter from '../../services/withRouter';
import { connect } from "react-redux";
import './watch.scss'
import { showLoader, hideLoader } from "../../action/application";
import ApiServices from '../../services/apiServices';
import CryptoJS from 'crypto-js';
class Watch extends Component {
    player;
    playerPause = false;
    userInfo;
    constructor(props) {
      super(props);
      this.state = {
        videoData: {},
        skipIntro: false,
        loader: false,
        resume: 0
      }
    }
    componentWillMount() {
      this.props.dispatch( showLoader() )
      if(localStorage.getItem('userKey')) {
          const bytes = CryptoJS.AES.decrypt(localStorage.getItem('userKey'), process.env.REACT_APP_SECERET_KEY);
          this.userInfo = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        } 
        
    }
    getResume() {
      const {flimId,episodeId} = this.props.params;
      const url = `movie/resume/${flimId}/${episodeId? episodeId : ''}`
      ApiServices.callPostAPI(url,{
        userid: this.userInfo.uid
      }).then(data => {
        if(data.status === 200 && data.data.valid) {
          console.log('data', data);
          this.setState({
            resume: data.data.data
          })
        }
      }).catch(error=>{
        console.log(error)
      })
    }
    setPlayerConfig(videoInfo) {
      console.log('player intialize', this.player);
      this.getResume();
        const {type} = this.props.params;
        const watchUrl = type === 'promo' ? videoInfo.trailerUrl :  videoInfo.videoUrl ;
        // const setNextEpisodes = videoInfo.episodes > videoInfo.sequence ? true : false;
        const setNextEpisodes =  false;
        const seasons = false;
        const setNextPromo = false;
        const selector = '#player';
        this.player = new window.Plyr(selector, {
            debug: false,
            autoplay: true,
            title: videoInfo.title,
            iconUrl: 'https://d12it2ctp7su6p.cloudfront.net/shortflix-web-static/image/plyr.svg',
            keyboard: {
              global: true,
            },
            invertTime: false,
            storage: { enabled: false, key: 'plyr' },
            controls: [
              'play-large', // The large play button in the center
      
              'restart', // Restart playback
      
              'rewind', // Rewind by the seek time (default 10 seconds)
      
              'play', // Play/pause playback
      
              'fast-forward', // Fast forward by the seek time (default 10 seconds)
      
              'progress', // The progress bar and scrubber for playback and buffering
      
              'duration',
      
              'current-time', // The current time of playback
      
              'mute', // Toggle mute
      
              'volume', // Volume control
      
              // 'help', // help
      
              'seasons', // seasons
      
              'captions', // Toggle captions
      
              'settings', // Settings menu
      
              'fullscreen', // Toggle fullscreen
            ],
            settings: ['quality'],
            // setImgPath: environment.assetUrl + 'images/plyr.svg',
            tooltips: {
              controls: true,
            },
            captions: {
              active: true,
              update: true,
            },
            hls: {
              enabled:  true,
              src: watchUrl
            },
            setNextEpisodes,
            seasons,
            setNextPromo
      
          });
          this.props.dispatch( hideLoader() )
          this.player.on('enterfullscreen', (event) => {
            this.isFullscreen = true;
          });
          this.player.on('exitfullscreen', (event) => {
            this.isFullscreen = false;
          });
          this.player.on('ended', (event) => {
            console.log('On player end');
          });
          this.player.on('timeupdate', () => {
            if (this.player && this.player.currentTime > 0) {
              const skip = this.state.videoData.skipstart > 0 && this.player.currentTime >= this.state.videoData.skipstart && this.player.currentTime <= this.state.videoData.skipend  ? true : false;
              if(this.state.skipIntro !== skip) {
                this.setState({skipIntro: skip});
              }
              // ((this.player.duration - 10) <= this.player.currentTime) ? true : false;
            }
          });
          this.player.on('seeking', (event) => {
            console.log('player seek');
          });
          this.player.on('pause', (event) => {
            console.log('player pause');
            this.playerPause = true;
          });
          this.player.on('play', (event) => {
            console.log('player play');
          });
          this.player.on('loadeddata', () => {
            this.player.currentTime = (Number(this.state.resume)) ? Number(this.state.resume) : 0;
          });
          this.player.on('next-episodes', (event) => {
            if (event.detail.plyr.nextEpisodes) {
                console.log('play next video');
                if(this.player && this.player.currentTime && this.player.currentTime > 0 && type !== 'promo'){
                  this.updateWatchedDuration();
              }
                this.setState({
                  loader: true
                })
                this.props.dispatch( showLoader() );
                const url = this.props.params.type === 'series' ? `/watch/series/${this.state.videoData.filmid}/short${this.state.videoData.sequence + 1 }` : `/watch/movie/${this.state.videoData.filmid}`;
                this.props.navigate(url);
                // this.getVideoContent(this.state.videoData.filmid, epidodeData)
                // this.getNextEpisode();
                // this.playNextEpisode('nextEpisode');
            }
          });
          this.innerVideoElement = document.getElementById('inner-video');
          if (this.innerVideoElement) {
            document.getElementsByClassName('plyr--video')[0].appendChild(this.innerVideoElement);
          }
        
    }
    destoryPlayer() {
      this.player.stop();
      this.player.destroy();
      document.getElementById('inner-video')
      this.setState({
        loader: true
      },()=> {this.render()})
    }
    componentDidMount() {
      
        const {flimId,episodeId} = this.props.params;
        this.setState({
            videoData: {},
            skipIntro: false,
            loader: true
          },() =>{
            this.getVideoContent(flimId, episodeId);
          }
        )

    }
    componentWillReceiveProps(nextProps) {
      
    }
    goBack() {
      const {type} = this.props.params; 
        if(this.player.currentTime && this.player.currentTime > 0 && type !== 'promo'){
            this.updateWatchedDuration();
        }
        if (this.player) {
          this.player.destroy(() => { }, true);
        }
      window.history.back();
    }
    componentWillUnmount() {
        const {type} = this.props.params; 
        if(this.player && this.player.currentTime && this.player.currentTime > 0 && type !== 'promo'){
            this.updateWatchedDuration();
        }
    }
   async updateWatchedDuration() {
    const {type} = this.props.params; 
     const position = (this.player && this.player.currentTime) ? Math.round(this.player.currentTime) : 0
        this.player.pause();
        this.player.stop();
        this.player.destroy();
        this.destoryPlayer();
        const videoInfo = this.state.videoData;
        ApiServices.callPutAPI('activity/stop_video',{
            channel: 'web',
            duration: position,
            episodeid: type === 'series' ? videoInfo.sequence : '',
            filmid: videoInfo.filmid,
            movietype: videoInfo.type,
            position: position,
            title: videoInfo.title,
            userid: this.userInfo.uid
        }).then(result=>{
            
              
          }).catch(error=>{ 
            console.log(error)
          })
    }
    getVideoContent(flimId, episodeId = null) {
      const url = episodeId ? `episode/${flimId}/${episodeId}` :  `movie/${flimId}`;
      ApiServices.callGetAPI(url).then(result=>{
        if(result.status === 200 && result.data.valid) {
          if(result.data.data ){
            // this.props.dispatch( setUrl(result.data.data[0]) );
            const videoInfo = episodeId ? result.data.data[0] : result.data.data;            
            this.setState({
              videoData: videoInfo,
              skipIntro: false,
              loader: false
            },()=>{
              this.setPlayerConfig(videoInfo);
            })
            
          }
        }
      }).catch(error=>{ 
        console.log(error)
      })
    }
    getNextEpisode() {
      const {videoInfo} = this.props
      
      
      ApiServices.callGetAPI(`episode/${videoInfo.filmid}/short${videoInfo.sequence + 1}`).then(result=>{
        if(result.status === 200 && result.data.valid) {
          if(result.data.data ){
            // this.props.dispatch( setUrl(result.data.data[0]) );
            // console.log(result.data.data[0]);
            this.setState({
              videoData: result.data.data[0],
              skipIntro: false,
              loader: false
            },()=> {
              setTimeout(this.setPlayerConfig(result.data.data[0]), 1000);
            })
            
            
          }
        }
      }).catch(error=>{ 
        console.log(error)
      })
    }
    skipIntro() {
      this.player.currentTime = Number(this.state.videoData.skipend);
    }
    render() { 
        
        return(
          <>
          {
            !this.state.loader ? 
            <div class="video-plyr flex">
                <video controls playsinline id="player" autoplay="true">
                
                </video>
                <div id="inner-video">
                  <span  style={{display: "flex"}} className='close-icon-video'  onClick={() => this.goBack() }>
                    <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m12.45 37.65-2.1-2.1L21.9 24 10.35 12.45l2.1-2.1L24 21.9l11.55-11.55 2.1 2.1L26.1 24l11.55 11.55-2.1 2.1L24 26.1Z"/></svg>
                  </span>
                  {
                    this.state.skipIntro ?
                    <span className='skip-intro' onClick={() => this.skipIntro()}>Skip Intro</span> 
                    : ''
                  }
                  <div class="video-titles">
                    <div class="player-title-detail">
                      <h2> {this.props.params.type === 'series'?  this.state.videoData.title : this.state.videoData.title}</h2>
                    </div>
                  </div>  
                </div>
                
            </div>
            :'' 
          }
           </> 
            
        )
    }
}

const mapStateToProps = state => ({ 
    loading: state.application.loading,
    videoInfo: state.application.videoInfo,
    PreviousUrl: state.application.PreviousUrl
    });


export default withRouter(connect(mapStateToProps)(Watch))