import axios  from 'axios'
import CryptoJS from 'crypto-js';
class ApiServices {
    getHeader() {
        const userToken = localStorage.getItem('userKey')
        if(userToken) {
            const bytes = CryptoJS.AES.decrypt(localStorage.getItem('userKey'), process.env.REACT_APP_SECERET_KEY);
            const userInfo = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            console.log(userInfo)
            return {
                headers: {
                    apikey: process.env.REACT_APP_API_KEY,
                    appversion: process.env.REACT_APP_VERSION,
                    authorization: userInfo.stsTokenManager.accessToken,
                    instanceid: userInfo.uid
                }
            }
        }else {
            return{
                headers: {
                    appversion: process.env.REACT_APP_VERSION,
                    apikey: process.env.REACT_APP_API_KEY
                }
            };
        }
        
    }
    callGetAPI(url) {
        const headers = this.getHeader();
        return new Promise((resolve, reject) => {
            axios.get( process.env.REACT_APP_API_URL+url, headers).then(response => {
                resolve(response)
            }).catch(error=>{
                reject(error)
            })
        });
    }
    MailGetAPI(url) {
        const headers = this.getHeader();
        return new Promise((resolve, reject) => {
            axios.get('http://35.174.153.53/api/v1/course?page=1&limit=7', headers).then(response => {
                resolve(response)
            }).catch(error=>{
                reject(error)
            })
        });
    }
    callPostAPI(url, data) {
        const headers = this.getHeader();
        return new Promise((resolve, reject) => {
            axios.post( process.env.REACT_APP_API_URL+url, data, headers).then(response => {
                resolve(response)
            }).catch(error=>{
                reject(error)
            })
        });
    }
    callPutAPI(url, data) {
        const headers = this.getHeader();
        return new Promise((resolve, reject) => {
            axios.put( process.env.REACT_APP_API_URL+url, data, headers).then(response => {
                resolve(response)
            }).catch(error=>{
                reject(error)
            })
        });
    }

    validateUser() {
        const headers = this.getHeader();
            axios.get( process.env.REACT_APP_API_URL+'membership/validate',  headers).then(response => {
                if(response.status === 200 && response.data.valid) {
                    return response.data.data.premiumuser === 1 ? true : false;
                } else {
                    return false;
                }
            }).catch(error=>{
                return false;
            })
    }
}

export default new ApiServices();