import React, { Component } from 'react'
import './detail.scss'
import Card from '../../components/card/Card';
import withRouter from '../../services/withRouter';
import ApiServices from '../../services/apiServices';
import { connect } from "react-redux";
import CryptoJS from 'crypto-js';
import { showLoader, hideLoader, setUrl, PreviousUrl } from "../../action/application";
class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      movieDate : {},
      loadSkelton: false,
      episodeData: [],
      memberShip: false,
      activeMenu: 'about',
      userInfo: {},
      resume: {}
    };
  }
  componentWillMount() {
    if(localStorage.getItem('userKey')) {
      const bytes = CryptoJS.AES.decrypt(localStorage.getItem('userKey'), process.env.REACT_APP_SECERET_KEY);
      const user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      this.setState({userInfo: user});
    }
    this.props.dispatch( showLoader() )
    const {slug } = this.props.params;
    this.getMovieInfo(slug);
    this.checkMembership();
  }
  getMovieInfo(slug) {
    ApiServices.callGetAPI('movie/'+ slug).then(result=>{
      if(result.status === 200 && result.data.valid) {
        this.props.dispatch( hideLoader() )
        let loader = false;
        this.getResume(result.data.data);
        if(result.data.data && ( result.data.data.type !== 'Film' && result.data.data.type !== 'film') ){
          loader =  true;
          this.getEpisodeInfo(slug)
        }
        this.setState({movieDate: result.data.data, loadSkelton: loader});
      }
    }).catch(error=>{ 
      console.log(error)
    })
  }
  async getEpisodeInfo(slug) {
    ApiServices.callGetAPI('movie/'+ slug +'/episodes/'+ this.state.userInfo.uid).then(result=>{
      if(result.status === 200 && result.data.valid) {
        this.setState({episodeData: result.data.data,loadSkelton: false, resume:{
          duration: result.data.data[0].recentepisodeposition,
          episode: result.data.data[0].recentepisode
        }})
      }
    }).catch(error=>{ 
      console.log(error)
    })
  }
  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h === 1 ? " h, " : " h, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " m, " : " m, ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " s" : " s") : "";
    return hDisplay + mDisplay + sDisplay;
}
  getResume(movieData) {
    ApiServices.callPostAPI('movie/resume/'+ movieData.filmid,{
      userid: this.state.userInfo.uid
    }).then(data => {
      if(data.status === 200 && data.data.valid) {
        console.log('data', data);
        this.setState({
          resume: {
            duration: data.data.data
          }
        })
      }
    }).catch(error=>{
      console.log(error)
    })
  }
  async checkMembership() {
    ApiServices.callGetAPI('membership/validate').then(result=>{
      if(result.status === 200 && result.data.valid) {
        const membership = result.data.data.premiumuser === 1 ? true : false;
        this.setState({memberShip: membership});
        } else {
          this.setState({memberShip: false});
        }
      
    }).catch(error=>{ 
      console.log(error)
      this.setState({memberShip: false});
    })
  }
  watchpromo() {
    this.props.dispatch( setUrl(this.state.movieDate) );
    this.props.dispatch( PreviousUrl(window.location.pathname) );
    this.props.navigate('/watch/promo/'+ this.state.movieDate.filmid);
  }
  watchvideo() {
    this.props.dispatch( setUrl(this.state.movieDate) );
    this.props.dispatch( PreviousUrl(window.location.pathname) );
    const url = this.state.movieDate.type === 'Film' || this.state.movieDate.type === 'film' ? `/watch/movie/${this.state.movieDate.filmid}` : `/watch/series/${this.state.movieDate.filmid}/short1`;
    this.props.navigate(url);
  }
  contentScroll(id) {
    const viewInto = document.getElementById(id);
    this.setState({activeMenu: id});
    viewInto.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }
  buyPlans() {
    console.log(window.location.pathname);
    this.props.dispatch( PreviousUrl(window.location.pathname) );
    this.props.navigate('/subscricption'); 
  }
  render() {
    return (
      <div className='detail'>
        <div className='main-body detail-top flex'>
          <div className='detail-top-left'>
            <h1>{this.state.movieDate.title}</h1>
            <ul className="flex badges">
              { this.state.movieDate.display_type ? 
                 <li>
                 <span className="badge plain medium">{this.state.movieDate.display_type}</span>
               </li> : ''
              }
             
              { this.state.movieDate.durationtext && this.state.movieDate.durationtext !== '0m'? <li>
                <span className="badge plain medium">{this.state.movieDate.durationtext} </span>
              </li> : ''}
              {
                this.state.movieDate.releaseyear? 
                <li>
                <span className="badge plain medium">{this.state.movieDate.releaseyear}</span>
              </li>: ''
              }
              
              <li>
                <span className="badge plain medium">{this.state.movieDate.rating}</span>
              </li>
              {
                this.state.movieDate.subtitles  && this.state.movieDate.subtitles !== 'no'? 
                <li className='badge plain medium'>
                <span>
                  <svg className='flex' fill='#fff' xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M5 8.5V10h1.5V8.5Zm8.5 3V13H15v-1.5ZM8 8.5V10h7V8.5Zm-3 3V13h7v-1.5ZM3.5 16q-.625 0-1.062-.438Q2 15.125 2 14.5v-9q0-.625.438-1.062Q2.875 4 3.5 4h13q.625 0 1.062.438Q18 4.875 18 5.5v9q0 .625-.438 1.062Q17.125 16 16.5 16Zm0-1.5h13v-9h-13v9Zm0 0v-9 9Z"/></svg>
                </span>
              </li>:''
              }
              
            </ul>
            <p>{this.state.movieDate.description}</p>
            <div className='cta flex'>
              {this.state.movieDate.trailerUrl && (this.state.movieDate.display_type !==   'Comming Soon' || !this.state.memberShip) ? <button className='button flex align-items-center justify-content-center' onClick={() => this.watchpromo() }>
                <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" fill='#fff'><path d="M13.333 31.583V8.25l18.334 11.667Z"/></svg>
                Watch Trailer
              </button> : this.state.movieDate.display_type ===   'Comming Soon' && !this.state.memberShip ? 
              <button className='button flex align-items-center justify-content-center' onClick={() => this.watchvideo() }>
                <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" fill='#fff'><path d="M13.333 31.583V8.25l18.334 11.667Z"/></svg>
                Play Now
              </button> :
              ''}
              
              {this.state.memberShip ?  
              <button className='button flex align-items-center justify-content-center' onClick={() => this.watchvideo() }>
                <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" fill='#fff'><path d="M13.333 31.583V8.25l18.334 11.667Z"/></svg>
                {this.state.resume.duration && this.state.movieDate.display_type !==   'Coming Soon'? (this.state.resume.episode ? 'Resume-E'+ this.state.resume.episode.replace('short', ''): 'Resume' ):'Play Now'}
              </button> :
              
              <button className='button flex align-items-center justify-content-center' onClick={() => this.buyPlans() }>
              Subscribe
            </button>
              }
            </div>
            <ul className="share-button flex">
              <li className="none">
                <div className="cursor relative">
                  <div className="custom-icon black-bg flex items-center justify-content-center ">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" fill='#fff'><path d="m12 20.325-1.1-1q-2.5-2.25-4.125-3.875T4.2 12.562q-.95-1.262-1.325-2.3Q2.5 9.225 2.5 8.15q0-2.125 1.438-3.563Q5.375 3.15 7.5 3.15q1.3 0 2.475.612Q11.15 4.375 12 5.525q.85-1.15 2.025-1.763Q15.2 3.15 16.5 3.15q2.125 0 3.562 1.437Q21.5 6.025 21.5 8.15q0 1.075-.375 2.112-.375 1.038-1.325 2.3-.95 1.263-2.575 2.888-1.625 1.625-4.125 3.875Zm0-2.025q2.4-2.15 3.95-3.7 1.55-1.55 2.45-2.688.9-1.137 1.25-2.012T20 8.15q0-1.5-1-2.5t-2.5-1q-1.175 0-2.188.675Q13.3 6 12.725 7.2h-1.45q-.6-1.225-1.6-1.888-1-.662-2.175-.662-1.5 0-2.5 1t-1 2.5q0 .875.35 1.75t1.25 2.012q.9 1.138 2.45 2.675Q9.6 16.125 12 18.3Zm0-6.825Z"/></svg>
                    <sup className="regular absolute">  </sup>
                  </div>
                  <span className="medium ">Like</span>
                </div>
              </li>
              <li className="">
                <div className="cursor relative" onClick={()=>{window.open("https://api.whatsapp.com/send?text="+ window.location.href);}}>
                  <div className="custom-icon black-bg flex items-center justify-content-center ">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" fill='#fff'><path d="m5.425 10.625 2.2.925q.4-.825.863-1.575.462-.75 1.012-1.5L8.05 8.2q-.1-.025-.162 0-.063.025-.138.075Zm3.4 1.875 2.825 2.825q1.125-.475 2.325-1.3 1.2-.825 2.275-1.9 1.675-1.675 2.6-3.663.925-1.987.925-4.087-2.1 0-4.087.912Q13.7 6.2 12 7.9q-1.075 1.075-1.9 2.275T8.825 12.5Zm4.8-2q-.5-.525-.5-1.25t.5-1.225q.525-.5 1.262-.5.738 0 1.238.5t.5 1.225q0 .725-.5 1.25-.5.5-1.238.5-.737 0-1.262-.5Zm-.1 8.225L15.85 16.4q.075-.075.1-.138.025-.062 0-.162l-.3-1.45q-.725.55-1.487 1-.763.45-1.563.875ZM21.125 3q.325 2.9-.675 5.487-1 2.588-3.3 4.888l-.088.087-.087.088.45 2.25q.075.45-.05.888-.125.437-.45.737L13 21.35l-1.875-4.425L7.2 13l-4.4-1.875L6.7 7.2q.3-.3.738-.438.437-.137.887-.062l2.3.475q.05-.05.075-.087.025-.038.075-.088 2.3-2.3 4.888-3.313Q18.25 2.675 21.125 3Zm-16.6 13.075q.725-.725 1.775-.738 1.05-.012 1.775.738.75.725.737 1.775-.012 1.05-.737 1.775-.575.575-1.862.975-1.288.4-3.338.65.25-2.025.663-3.312.412-1.288.987-1.863Zm1.075 1.05q-.275.275-.525.937-.25.663-.35 1.338.7-.075 1.35-.325.65-.25.925-.525.3-.3.312-.725.013-.425-.287-.725t-.713-.288q-.412.013-.712.313Z"/></svg>
                  </div>
                  <span className="medium ">Share</span>
                </div>
              </li>
              <li className="none add">
                <div className="cursor relative none">
                  <div className="custom-icon black-bg flex items-center justify-content-center ">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" fill='#fff'><path d="M11.25 18.75v-6h-6v-1.5h6v-6h1.5v6h6v1.5h-6v6Z"/></svg>
                  </div>
                  <span className="medium ">Watchlist</span>
                </div>
              </li>
              <li>
              <div className="cursor relative none">
                  <div className="custom-icon black-bg flex items-center justify-content-center "><svg fill="#fff" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M22,3H19V2a1,1,0,0,0-1-1H6A1,1,0,0,0,5,2V3H2A1,1,0,0,0,1,4V6a4.994,4.994,0,0,0,4.276,4.927A7.009,7.009,0,0,0,11,15.92V18H7a1,1,0,0,0-.949.684l-1,3A1,1,0,0,0,6,23H18a1,1,0,0,0,.948-1.316l-1-3A1,1,0,0,0,17,18H13V15.92a7.009,7.009,0,0,0,5.724-4.993A4.994,4.994,0,0,0,23,6V4A1,1,0,0,0,22,3ZM5,8.829A3.006,3.006,0,0,1,3,6V5H5ZM16.279,20l.333,1H7.387l.334-1ZM17,9A5,5,0,0,1,7,9V3H17Zm4-3a3.006,3.006,0,0,1-2,2.829V5h2ZM10.667,8.667,9,7.292,11,7l1-2,1,2,2,.292L13.333,8.667,13.854,11,12,9.667,10.146,11Z"/></svg>
                  </div>
                  <span className="medium ">Competetion</span>
                </div>
              </li>
            </ul>
          </div>
          <div className='detail-top-right flex'>
            {
                this.state?.movieDate.image ? <img src={this.state?.movieDate?.image[0]} alt="" /> : 
                <span className='card-thumb skeleton-animation skeleton-card'></span> 
            }
            
          </div>
        </div>
        <div className='main-body detail-menu'>
          <ul className='flex'>
            <li className={`cursor relative ${this.state.activeMenu === 'about' ? 'active' : ''}`} onClick={() => this.contentScroll('about') }><span className='flex'>About</span></li>
            {
              this.state.movieDate.type === 'Film' || this.state.movieDate.type === 'film' ? '' : 
              <li className={`cursor relative ${this.state.activeMenu === 'chapter' ? 'active' : ''}`} onClick={() => this.contentScroll('chapter') }><span className='flex'>Chapter</span></li>
            }
            
          </ul>
        </div>
        <div className='main-body detail-bottom'>
          <div className='about-detail' id="about">
          <h2 className='semi-bold none'>{this.state.movieDate.title}</h2>
          <div className='tags none'>#ShorFlim #Murder #Horror</div>
          <p className="none">{this.state.movieDate.description}</p>
             <ul>
              {
              this.state.movieDate.directors ? 
              <li className='flex'><span className='que'>Directors</span> <span className='det'>{this.state.movieDate.directors}</span></li>
              :''  
              }
              {
              this.state.movieDate.starring ? 
              <li className='flex'><span className='que'>Starring</span> <span className='det'>{this.state.movieDate.starring}</span></li>
              :''  
              }
              {
              this.state.movieDate.producers ? 
              <li className='flex'><span className='que'>Producers</span> <span className='det'>{this.state.movieDate.producers}</span></li>
              :''  
              }
              {
              this.state.movieDate.genres ? 
              <li className='flex'><span className='que'>Genres</span> <span className='det'>{this.state.movieDate.genres}</span></li>
              :''  
              }
              {
              this.state.movieDate.audiolanguages ? 
              <li className='flex'><span className='que'>Audio languages</span> <span className='det'>{this.state.movieDate.audiolanguages}</span></li>
              :''  
              }
              {
              this.state.movieDate.subtitles && this.state.movieDate.subtitles !== 'no'? 
              <li className='flex'><span className='que'>Subtitles</span> <span className='det'>{this.state.movieDate.subtitles}</span></li>
              :''  
              }
              {
              this.state.movieDate.contentadvisory ? 
              <li className='flex'><span className='que'>Content advisory</span> <span className='det'>{this.state.movieDate.contentadvisory}</span></li>
              :''  
              }
              
              {/* <li className='flex'><span className='que'>Starring</span> <span className='det'>Tom Cruise, Cobie Smulders</span></li> */}
            </ul>
          </div>
          <div className='chapters-detail' id="chapter">
            {/* <div className="select none">
              <select name="format" id="format" className='none'>
                  <option value="chapter-1">Chapter-1</option>
                  <option value="chapter-2">Chapter-2</option>
                  <option value="chapter-3">Chapter-3</option>
              </select>
            </div> */}
            {
              this.state.episodeData.length > 0 ? 
              <Card  cardType={'episode'} cardData = {{movies: this.state.episodeData, memberShip: this.state.memberShip}} /> 
              : ''
            }
            {
            
            this.state.loadSkelton && this.state.loadSkelton ? 
            <Card  cardType={'skeleton'} cardData = {this.loader} />
            : ''  
            }
            
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({ loading: state.application.loading });
export default withRouter(connect(mapStateToProps)(Detail))